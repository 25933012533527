<div matDialogTitle>Radera</div>
<div matDialogContent>
  <div>Är du säker på att du vill radera {{whatDoDelete}}?</div>

</div>
<div matDialogActions>
  <button
    (click)="delete()"
    color="primary"
    mat-raised-button>Ja
  </button>
  <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
</div>
