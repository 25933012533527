import {Inject, Injectable, signal} from '@angular/core'
import {Router} from '@angular/router'
import {
  HelperService,
  SingleSignOnService,
  SparbankenUser
} from '@sparbanken-syd/sparbanken-syd-bankid'
import {
  BehaviorSubject,
  EMPTY,
  merge,
  Observable,
  of,
  ReplaySubject
} from 'rxjs'
import {catchError, map, switchMap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {LOGIN_ROUTE_PATH} from '../application/data-types'
import {LOCAL_STORAGE} from '../application/localstorage.provider'

const ACCESS_TOKEN_NAME = 'ir-at'

export const PORTFOLIO_MANAGER_ROLE = 'irPfAdmin'
export const USER_ROLE = 'irUser'
export const ADMIN_ROLE = 'irAdmin'

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  /**
   * The access token, primarily needed for the auth interceptor
   */
  public accessToken$ = signal<string | null>(null)

  // Possible user roles as signals
  public isIrAdmin$ = signal(false)
  public isIrUser$ = signal(false)
  public isPortfolioManager$ = signal(false)

  public currentUser$: Observable<SparbankenUser | null>
  public pCurrentUser$: BehaviorSubject<SparbankenUser | null> =
    new BehaviorSubject<SparbankenUser | null>(null)

  /**
   * Anyone can send status messages here...
   * Statuses are shown while loading.
   */
  public status$ = new ReplaySubject<string>(1)

  /**
   * Send and receive error messages.
   */
  public errors$ = new ReplaySubject<string | null>(1)

  constructor(
    private helperService: HelperService,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage,
    private ssoService: SingleSignOnService,
    private router: Router
  ) {
    this.currentUser$ = this.pCurrentUser$.asObservable()
  }

  /**
   * This is called from the app module bootstrapper only. So
   * it will happen once and once only.
   */
  public bootstrap(): Observable<boolean> {
    // Start by initiating SSO, it will take a while.
    // Merge will take both, Concat will take them in order
    return merge(
      this.sso(),
      of(this.injectedLocalStorage.getItem(ACCESS_TOKEN_NAME))
    )
      .pipe(
        switchMap((value: string | null) => {
          return this.setToken(value)
        })
      )
  }

  /**
   * Called whenever we have token, a token can come from three valid source
   *
   * 1. Localstorage
   * 2. From the SSO service
   * 3. From BankID login.
   *
   * We do not care, and we validate and set whatever we get.
   */
  public setToken(token: string | null): Observable<boolean> {
    const payload = HelperService.GetTokenPayload(token)
    if (payload) {
      this.accessToken$.set(token)
      // String assertion needed since only payload is verified,
      // but we know that it is a string...
      this.injectedLocalStorage.setItem(ACCESS_TOKEN_NAME, token as string)

      // Logout if we don't have any relevant roles
      if (![PORTFOLIO_MANAGER_ROLE, USER_ROLE, ADMIN_ROLE].some(role =>
        payload.roles.includes(role))) {
        this.logout()
      }

      this.isIrAdmin$.set(payload.roles.includes(ADMIN_ROLE))
      this.isIrUser$.set(payload.roles.includes(USER_ROLE))
      this.isPortfolioManager$.set(payload.roles.includes(PORTFOLIO_MANAGER_ROLE))

      return this.helperService.getCurrentUser(environment.authServiceUrl)
        .pipe(map((user: SparbankenUser) => {
          this.pCurrentUser$.next(user)
          return false
        }))
    }
    return of(true)
  }

  /**
   * Call the SSO service, if we get something we return
   * that. Otherwise, nothing. Must be anonymous since we
   * call it from merge
   */
  public sso = (): Observable<string> | never => {
    return this.ssoService.getToken(environment.authServiceUrl, environment.domain)
      .pipe(
        catchError(() => {
          // We MUST log out if the SSO service says we are logged out!
          this.logout()
          return EMPTY
        })
      )
  }

  /**
   * Reset all admin values, including SSO and
   * go back to log-in.
   */
  public logout(): void {
    // This can potentially be a long list of resets...
    this.accessToken$.set(null)
    // this.isAdmin$.set(false)
    this.injectedLocalStorage.removeItem(ACCESS_TOKEN_NAME)
    // Blindly just log out from SSO, ignore any errors
    this.ssoService.deleteToken(environment.authServiceUrl).subscribe()
    // Go to log-in
    this.router.navigate([LOGIN_ROUTE_PATH]).then()

    // Reset all possible roles and current user
    this.isIrAdmin$.set(false)
    this.isIrUser$.set(false)
    this.isPortfolioManager$.set(false)
    this.pCurrentUser$.next(null)
  }
}
