import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface WaitDialogData {
  title: string
  message: string[]

  // Show the close button, default we do not.
  close?: boolean
}

@Component({
  selector: 'spb-wait-dialog',
  template: `
      <span matDialogTitle>{{ data.title }}</span>
      <div matDialogContent><p style="white-space: pre-wrap">
          @for (message of data.message;track $index) {
              <p>{{ message }}</p>
          }
      </div>
      @if (data.close) {
          <div matDialogActions>
              <button mat-raised-button matDialogClose="" color="primary">Stäng</button>
          </div>
      }
  `
})
export class WaitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: WaitDialogData) {
  }
}
