import {Component, OnInit} from '@angular/core'
import {MatTableDataSource} from '@angular/material/table'
import {Customer, CustomerService, IrDocument} from '../../../services/customer.service'
import {MorningstarService} from '../../../services/morningstar.service'
import {MatDialog} from '@angular/material/dialog'
import {DocumentDeleteDialogComponent} from '../document-delete-dialog/document-delete-dialog.component'

export interface ConfirmDeleteData {
  index: number
  documents: IrDocument[]
}

@Component({
  selector: 'spb-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  /**
   * Boilerplate for material tables
   */
  public displayedColumns: string[] = ['created', 'advisor', 'signed', 'delete']

  /**
   * Bla bla table source.
   */
  public dataSource: MatTableDataSource<IrDocument> = new MatTableDataSource<IrDocument>()

  private documents: IrDocument[] = []

  constructor(
    public morningstarService: MorningstarService,
    private customerService: CustomerService,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.customerService.currentCustomer$.pipe().subscribe({
      next: (customer: Customer) => {
        this.documents = customer.documents
        this.dataSource = new MatTableDataSource<IrDocument>(customer.documents)
      }
    })
  }

  /**
   * Heads up, this is test code since we do not have documents already
   */
  public delete(index: number): void {
    const data: ConfirmDeleteData = {index, documents: this.documents}

    this.dialog.open(DocumentDeleteDialogComponent, {
      data
    })

  }
}
