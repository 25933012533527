import {Component} from '@angular/core'
import {
  COUNSELLING_ROUTE_PATH,
  CUSTOMER_ROUTE_PATH,
  PORTFOLIO_ROUTE_PATH
} from '../../application/data-types'
import {ConfigService} from '../../services/config.service'


@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  protected readonly Boolean = Boolean

  public customerPath = '/' + CUSTOMER_ROUTE_PATH
  public portfolioPath = '/' + PORTFOLIO_ROUTE_PATH
  public counsellingPath = '/' + COUNSELLING_ROUTE_PATH

  /**
   * The constructor
   * @param configService - Holds the logged in state and tells ut what to show.
   */
  constructor(
    public configService: ConfigService
  ) {
  }
}
