import {Component} from '@angular/core'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss']
})
export class ErrorDisplayComponent {

  constructor(
    public configService: ConfigService
  ) {
  }
}
