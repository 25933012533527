import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {ReactiveFormsModule} from '@angular/forms'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {MatButtonModule} from '@angular/material/button'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatInputModule} from '@angular/material/input'
import {RouterModule} from '@angular/router'
import {CustomerComponent} from './customer/customer.component'
import {MatDialogModule} from '@angular/material/dialog'
import {AbasecStatusComponent} from './abasec-status/abasec-status.component'
import {HistoryComponent} from './customer/history/history.component'
import {MatTableModule} from '@angular/material/table'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {CreatePdfDialogComponent} from './create-pdf-dialog/create-pdf-dialog.component'
import {MatSelectModule} from '@angular/material/select'
import {ErrorDisplayComponent} from './error-display/error-display.component'
import {TestPdfComponent} from './test-pdf/test-pdf.component'
import {MatExpansionModule} from '@angular/material/expansion'
import {MatTooltipModule} from '@angular/material/tooltip'
import {DocumentDeleteDialogComponent} from './customer/document-delete-dialog/document-delete-dialog.component'
import {CommonDeleteDialogComponent} from './common-delete-dialog/common-delete-dialog.component'

@NgModule({
  declarations: [
    HeaderComponent,
    WaitDialogComponent,
    CustomerComponent,
    AbasecStatusComponent,
    HistoryComponent,
    CreatePdfDialogComponent,
    ErrorDisplayComponent,
    TestPdfComponent,
    DocumentDeleteDialogComponent,
    CommonDeleteDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    MatTableModule,
    MatCheckboxModule,
    MatSelectModule,
    MatExpansionModule,
    MatTooltipModule
  ],
  exports: [
    HeaderComponent,
    WaitDialogComponent,
    CustomerComponent,
    AbasecStatusComponent,
    ErrorDisplayComponent,
    TestPdfComponent
  ]
})
export class SpbCommonModule {
}
