import {Component, OnDestroy, OnInit} from '@angular/core'
import {AbasecStatus, CustomerService} from '../../services/customer.service'
import {Subject, Subscription} from 'rxjs'
import {switchMap, throttleTime} from 'rxjs/operators'

@Component({
  selector: 'spb-abasec-status',
  templateUrl: './abasec-status.component.html',
  styleUrls: ['./abasec-status.component.scss']
})
export class AbasecStatusComponent implements OnInit, OnDestroy {

  public status = 'unknown'

  public duration = ''

  public check$ = new Subject<any>()

  private status$ = new Subscription()

  constructor(
    public customerService: CustomerService
  ) {
  }

  public ngOnInit(): void {
    this.status$ = this.customerService.abasecStatus$.subscribe({
      next: (status: AbasecStatus) => {
        this.status = status.status.toLowerCase()
        this.setDuration(status)
      }
    })

    //
    // This is just me doing some training with Observable
    // Only check status once every 10 seconds even if clicked
    // many times.
    this.check$.pipe(
      throttleTime(10000),
      switchMap(() => this.customerService.checkTheFeelingsOfAbasec())
    ).subscribe()
  }

  public ngOnDestroy(): void {
    this.status$.unsubscribe()
  }

  private setDuration(status: AbasecStatus): void {
    const now = new Date().getTime()
    const then = new Date(status.change).getTime()
    let diff = Math.floor((now - then) / 1000)
    const divisors = [
      {divisor: 1, suffix: 'sekunder'},
      {divisor: 60, suffix: 'minuter'},
      {divisor: 60, suffix: 'timmar'},
      {divisor: 24, suffix: 'dagar'},
    ]
    let position = 0
    let suffix = ''
    while (diff > 100 && position < divisors.length) {
      const divisor = divisors[position++]
      suffix = divisor.suffix
      diff = Math.floor(diff / divisor.divisor)

    }
    this.duration = `${diff} ${suffix}`
  }
}
