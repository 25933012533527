<div matDialogTitle>Utskrift</div>
<div matDialogContent>
  <div class="content">{{ statusText }}</div>
  <mat-dialog-actions>
    @if (ready) {
      <button
        (click)="morningstarService.openDocument(pdfResult.id + '')"
        color="primary"
        mat-stroked-button
        matDialogClose=""
      >Skriv ut
      </button>
    } @if (ready && !corporate) {
      <button
        (click)="sign()"
        color="accent"
        mat-stroked-button
      >Digital Signering
      </button>
    }
    <button (click)="cancel()"
            color="primary"
            mat-raised-button
            matDialogClose=""
    >Avbryt
    </button>
  </mat-dialog-actions>
</div>
