import {Component} from '@angular/core'
import {MatSelectChange} from '@angular/material/select'
import {MorningstarService} from '../../services/morningstar.service'
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms'
import {CreatePdfDialogComponent} from '../create-pdf-dialog/create-pdf-dialog.component'
import {MatDialog} from '@angular/material/dialog'

@Component({
  selector: 'spb-test-pdf',
  templateUrl: './test-pdf.component.html',
  styleUrls: ['./test-pdf.component.scss']
})
export class TestPdfComponent {

  public form = new UntypedFormGroup({
    //id: new FormControl('9c1e2be7-31c5-4344-9451-a17b6bbf5d6a'),
    id: new UntypedFormControl('0014b2ce-261b-4e7b-9a09-95b550d51c9a'),
    status: new UntypedFormControl('')
  })

  constructor(public morningstarService: MorningstarService,
              private dialog: MatDialog) {
  }

  public setStatus(event: MatSelectChange): void {
    this.morningstarService.pdfStatus$.next({status: event.value, id: this.form.controls.id.value})
  }

  public open(): void {
    this.dialog.open(CreatePdfDialogComponent)
  }
}
