import {Component, Inject} from '@angular/core'
import {WaitDialogComponent} from '../../wait-dialog/wait-dialog.component'
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog'
import {CustomerService} from '../../../services/customer.service'
import {ConfirmDeleteData} from '../history/history.component'

@Component({
  selector: 'spb-confirm-delete-dialog',
  templateUrl: './document-delete-dialog.component.html',
  styleUrls: ['./document-delete-dialog.component.scss']
})
export class DocumentDeleteDialogComponent {

  constructor(
    private customerService: CustomerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DocumentDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteData
  ) {
  }

  public delete() {
    const ref = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Tar bort',
        message: ['Ett ögonblick...'],
        close: false
      }
    })
    const id = this.data.documents[this.data.index].id
    this.data.documents.splice(this.data.index, 1)
    this.customerService.deleteDocument(id).subscribe({
      next: () => ref.close()
    })
    this.dialogRef.close()
  }

}
