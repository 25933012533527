<div class="middle-holder">
  <div class="middle">
    <div [routerLink]="[counsellingPath]" class="logo">
      Investeringsrådgivning
    </div>

    <div class="middle">
      <div class="menu">
        @if (configService.isIrUser$()) {
          <div class="cursor-pointer" [routerLink]="[counsellingPath]">
            Rådgivning
          </div>
        }
        @if (configService.isIrUser$() || configService.isPortfolioManager$()) {
          <div class="cursor-pointer" [routerLink]="[portfolioPath]">
            Modellportföljer
          </div>
        }
      </div>

      <div class="spb-filler"></div>

      <div>
        <spb-abasec-status></spb-abasec-status>
      </div>

      <div>
        @if (configService.currentUser$ | async; as user) {
          <div class="name-holder">
            <div [matTooltip]="[
              configService.isIrUser$() ? 'U' : '',
              configService.isIrAdmin$() ? 'A' : '',
              configService.isPortfolioManager$() ? 'P' : ''
             ].filter(Boolean).join(':')">
              {{ user.name }}
            </div>
          </div>
        }
      </div>

      <div>
        <div class="menu"></div>
        <div class="filler"></div>
        <button [matMenuTriggerFor]="menu" mat-button>
          <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          @if (configService.isIrUser$() || configService.isIrAdmin$()) {
            <button [routerLink]="[customerPath]" mat-menu-item>
              Kundhantering
            </button>
          }
          <button (click)="configService.logout()" mat-menu-item>Logga ut
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
