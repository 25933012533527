import {Component, Inject, OnInit} from '@angular/core'
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog'
import {ConfigService} from '../../services/config.service'
import {Customer, CustomerService} from '../../services/customer.service'
import {
  CommonDeleteDialogComponent
} from '../common-delete-dialog/common-delete-dialog.component'

@Component({
  selector: 'spb-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  public customer: Customer = {} as any

  public form = new UntypedFormGroup({
    id: new UntypedFormControl(),
    sub: new UntypedFormControl({
      disabled: true,
      value: ''
    }),
    corporate: new UntypedFormControl({
      disabled: true,
      value: false
    }),
    firstName: new UntypedFormControl(null, [Validators.required]),
    lastName: new UntypedFormControl(),
    created: new UntypedFormControl(null),
    lastSave: new UntypedFormControl(null)
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private customerService: CustomerService,
    protected configService: ConfigService,
    private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.customerService.getCustomer(this.data.id).subscribe({
      next: (customer: Customer) => {
        this.customer = customer
        this.form.patchValue(customer)

        Object.values(this.form.controls)
          .filter(() => !this.configService.isIrUser$())
          .forEach((control: AbstractControl) => control.disable())

        if (!this.corporate) {
          this.form.controls.lastName.addValidators(Validators.required)
          this.form.controls.lastName.updateValueAndValidity()
        }
      }
    })
  }

  get corporate(): boolean {
    return this.form.controls.corporate.value
  }

  public save(): void {
    this.customer.firstName = this.form.controls.firstName.value
    this.customer.lastName = this.form.controls.lastName.value
    this.customerService.saveCustomer(this.customer).pipe(
    ).subscribe({
      next: (customer: Customer) => {
        this.customer = customer
        this.form.markAsPristine()
        this.form.patchValue(customer)
      }
    })
  }

  public delete(id: string): void {
    const whatToDelete = `${this.customer.firstName} ${this.customer.lastName}`

    this.dialog.open(CommonDeleteDialogComponent, {data: whatToDelete}).afterClosed().subscribe({
      next: (deleted: boolean) => {
        if (deleted) {
          this.customerService.deleteCustomer(id).subscribe()
        }
      }
    })
  }
}
