<p>Historik</p>
<table [dataSource]="dataSource" class="mat-elevation-z2" mat-table>
  <caption style="display: none;">List of Customers</caption>

  <ng-container matColumnDef="created">
    <th *matHeaderCellDef id="created" mat-header-cell>Skapad</th>
    <td *matCellDef="let document"
        (click)="morningstarService.openDocument(document.id)"
        class="link"
        mat-cell>{{ document.created  | date: 'yyyy-MM-dd HH:mm' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="advisor">
    <th *matHeaderCellDef id="advisor" mat-header-cell>Handläggare</th>
    <td *matCellDef="let document" class="wider" mat-cell>{{ document.advisor }}</td>
  </ng-container>

  <ng-container matColumnDef="signed">
    <th *matHeaderCellDef class="center" id="signed" mat-header-cell>Signerad</th>
    <td *matCellDef="let document" mat-cell>
      @if (document.signed) {
        <span>{{ document.updated  | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
      }
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th *matHeaderCellDef class="center" id="delete" mat-header-cell>Radera</th>
    <td (click)="delete(i)" *matCellDef="let document; let i = index" class="center" mat-cell>
      <mat-icon class="table-icon" color="warn">delete_outline</mat-icon>
    </td>
  </ng-container>


  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let element; columns: displayedColumns" mat-row>

  <tr *matNoDataRow class="no-data">
    <td [attr.colspan]="displayedColumns.length" class="no-data">
      Inga tidigare resultat finnes.
  </tr>
</table>
