import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs'
import {environment} from '../../environments/environment'
import {catchError, first, map, switchMap, tap} from 'rxjs/operators'
import {MorningstarAccount, MorningstarState} from './morningstar.service'
import {MatDialog} from '@angular/material/dialog'
import {CustomerComponent} from '../common/customer/customer.component'
import {NewCustomerComponent} from '../customers/new-customer/new-customer.component'
import {ConfigService} from './config.service'

export interface Customer {
  /**
   * UUID for user
   */
  id?: string

  /**
   * Like personnummer should be 13 digit format
   */
  sub: string

  /**
   * Given name as in Daniel
   */
  firstName: string

  /**
   * Family name as in "Svensson"
   */
  lastName: string

  /**
   * E-mail
   */
  email: string

  /**
   * Mobile phone number, must be in international format
   */
  mobile: string

  /**
   * Morningstar to be defined...
   */
  morningstar: MorningstarState

  /**
   * Documents a user have an array of documents, always
   * if not we have to append an empty list.
   */
  documents: IrDocument[]

  /**
   * If a company set this to true
   */
  corporate: boolean

  /**
   * Employee sid
   */
  sid: string

  /**
   * Employee office, if any
   */
  office: string

  /**
   * Employee name
   */
  employee: string

  /**
   * The time the customer was updated
   */
  updated: string
}

export const normalizeSub = (sub = ''): string => sub.replace('-', '').trim()

/**
 * An Ir Document is called so to avoid confusion with the "document"
 * it represents a document that have been submitted and possibly signed.
 *
 * It is always sent by an "handläggare", the sent date is given, we might
 * get a signed date from "nya fantastiska signeringsrummet från SDC"
 */
export interface IrDocument {

  /**
   * The document ID, a UUIDv4 thing
   */
  id: string
  /**
   * The date this was created. We now switch to ISO strings.
   */
  created: string

  /**
   * Advisor is the name of the advisor. We do not want to go through
   * the trouble of using s-id and look that up?
   *
   * The name as in Giggi Svensson
   */
  advisor: string

  /**
   * Last updated date, if signed we assume that date...
   */
  updated: number

  /**
   * True or false if the document is signed
   */
  signed: boolean

}

export interface AbasecStatus {
  status: 'UP' | 'DOWN' | 'UNKNOWN'
  change: string
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  /**
   * Let others use the raw data
   */
  public customers$ = new BehaviorSubject<Customer[]>([])

  /**
   * We hold "current customer here so that many views
   * can use it. This is set by "getCustomer". It can also be nullified
   * by externals
   *
   */
  public currentCustomer$ = new ReplaySubject<Customer>(1)

  public abasecStatus$ = new BehaviorSubject<AbasecStatus>({status: 'UNKNOWN', change: '0'})

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private configService: ConfigService
  ) {
  }

  public getCustomers(): Observable<Customer[]> {
    const url = `${environment.apiUrl}/users`
    return this.httpClient.get<Customer[]>(url).pipe(
      map((customers: Customer[]) => {
        this.customers$.next(customers)
        return customers
      })
    )
  }

  public getCustomer(id: string): Observable<Customer> {
    const url = `${environment.apiUrl}/users/${id}`
    return this.httpClient.get<Customer>(url).pipe(
      tap((customer: Customer) => {
          // this is a "hack" to prevent breaks if the stored customer does not have
          // morningstar set. Should be removed in the futures.
          customer.morningstar = customer.morningstar || {}
          this.currentCustomer$.next(customer)
        }
      )
    )
  }

  public getCustomerBySub(sub: string): Observable<Customer | undefined> {
    return this.customers$.pipe(
      first(),
      map((customers: Customer[]) => {
        return customers.find((customer: Customer) =>
          normalizeSub(customer.sub) === normalizeSub(sub)
        )
      })
    )
  }

  public saveCustomer(customer: Customer): Observable<Customer> {
    const url = `${environment.apiUrl}/users/${customer.id}`
    const cst = JSON.parse(JSON.stringify(customer))
    return this.httpClient.put<Customer>(url, cst).pipe(
      tap(() => {
        this.updateCustomers()
        this.getCustomer(customer.id as string).subscribe()
      })
    )
  }

  public createCustomer(customer: Customer): Observable<Customer> {
    const url = `${environment.apiUrl}/users`
    return this.httpClient.put<Customer>(url, customer).pipe(
      tap((createdCustomer: Customer) => {
        this.updateCustomers()
        this.getCustomer(createdCustomer.id as string).subscribe()
      })
    )
  }

  public deleteCustomer(id: string): Observable<void> {
    const url = `${environment.apiUrl}/users/${id}`
    return this.httpClient.delete<void>(url).pipe(
      tap(() => {
        this.customers$.next(
          this.customers$.getValue().filter((customer: Customer) => customer.id !== id))
      })
    )
  }

  public deleteDocument(documentId: string): Observable<Customer> {
    const url = `${environment.apiUrl}/documents/${documentId}`
    return this.httpClient.delete<Customer>(url).pipe(
      tap((customer: Customer) => {
        this.currentCustomer$.next(customer)
        this.updateCustomers()
      })
    )
  }

  /**
   * Fetches Accounts. we use PUT since we do not want the personnummer
   * in the URL.
   */
  public getAccounts(): Observable<MorningstarAccount[]> {
    const url = `${environment.apiUrl}/accounts`
    return this.currentCustomer$.pipe(
      first(),
      switchMap((customer: Customer) => {
        const data = {sub: customer.sub}
        return this.httpClient.put<MorningstarAccount[]>(url, data)
      }),
      catchError(() => {
        this.configService.errors$.next('Abasec har inte svarat, kundens konton kan saknas!')
        return of([])
      })
    )
  }

  /**
   * Opens a dialog for the customer details.
   * @param id - UUID of existing customer
   */
  public open(id: string): void {
    this.dialog.open(CustomerComponent, {data: {id}})
  }

  /**
   * Open the create customer dialog.
   * @param sub - Actually optional send in '' this just prefills the input.
   */
  public create(sub: string): void {
    this.dialog.open(NewCustomerComponent, {
      data: {
        sub
      }
    })
  }

  public checkTheFeelingsOfAbasec(): Observable<AbasecStatus> {
    const url = `${environment.apiUrl}/abasec/status`
    return this.httpClient.get<AbasecStatus>(url).pipe(
      tap((status: AbasecStatus) => this.abasecStatus$.next(status))
    )
  }

  public sendForSigning(documentId: string): Observable<Customer> {
    const url = `${environment.apiUrl}/documents/${documentId}`
    return this.currentCustomer$.pipe(
      first(),
      switchMap((customer: Customer) => {
        const data = {
          documentId,
          customerId: customer.id
        }
        return this.httpClient.put<Customer>(url, data)
      }),
      tap((updatedCustomer: Customer) => {
        this.currentCustomer$.next(updatedCustomer)
        this.updateCustomers()
      })
    )
  }

  private updateCustomers(): void {
    const url = `${environment.apiUrl}/users`
    this.httpClient.get<Customer[]>(url).subscribe({
      next: (customers: Customer[]) => this.customers$.next(customers)
    })
  }
}
