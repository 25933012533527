import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'spb-common-delete-dialog',
  templateUrl: './common-delete-dialog.component.html'
})
export class CommonDeleteDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CommonDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public whatDoDelete: string
  ) {
  }

  public delete() {
    this.dialogRef.close(true)
  }
}
